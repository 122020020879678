import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class YamlService {
  constructor(private httpClient: HttpClient) {}

  public fetchYaml(fileName) {
    return this.httpClient.get(`/assets/data/${fileName}`, { responseType: 'text' })
  }

}
<div class="voltar">
  <span class="end"><a href="javascript:history.back()" class="rounded"><i class="fas fa-arrow-left"></i></a></span>
</div>
<p>Aviso de Privacidade<br/>
Data de vigência: 01 de junho de 2023<br/>
Atualizado pela última vez em: 01 de junho de 2023<br/>
</p>

<p>Olá! Plataforma Ordine é uma plataforma que suporta operações de gestão de serviços.</p>

<p>Este Aviso se aplica somente quando a Plataforma Ordine é a Controladora de dados pessoais (exemplo: dados pessoais dos visitantes do site Plataforma Ordine e dados de contato business-to-business)). A Plataforma Ordine é um Processador, não um controlador, de dados pessoais que processamos em nome de nossos assinantes quando eles usam os produtos e serviços da Plataforma Ordine. Para maior clareza, isso significa que este Aviso não se aplica aos produtos e serviços da Plataforma Ordine. Se você tiver dúvidas relacionadas a como um assinante  utiliza seus dados pessoais, entre em contato diretamente com eles. Não somos responsáveis pelas práticas de privacidade ou segurança de dados de nossos Assinantes. Este Aviso também não se aplica a dados pessoais sobre funcionários atuais e antigos da Plataforma Ordine, candidatos a empregos ou contratados e agentes que atuam em funções semelhantes.</p>
 
<p>
<b>1. Introdução</b><br/>
Este Aviso se aplica à Plataforma Ordine. Os termos em letras maiúsculas que usamos, mas não estão definidos no Aviso (como Site, Serviços, Assinante, Agente, Informações de Contato do Agente, etc.) têm o significado fornecido em nosso Contrato Principal de Assinatura. 
</p>

<p>Quando este aviso se aplica? Este Aviso se aplica somente aos dados pessoais que a Plataforma Ordine manipula como um Controlador (ou seja, onde a Plataforma Ordine controla como e por que seus dados pessoais são processados). Isso inclui quando você:</p>

<p>
Visita ou interage com o Site Plataforma Ordine.com, os aplicativos móveis Plataforma Ordine, o Marketplace Plataforma Ordine, o Portal Plataforma Ordine Developer, nossas páginas de mídia social de marca e outros Sites que operamos (coletivamente, nossas “Propriedades Digitais”);
Registra-se ou participa de nossos webinars, eventos, programas, marketing e atividades promocionais;
Interage conosco pessoalmente, como quando você visita nossos escritórios; e Informa-se ou envolve-se em transações comerciais conosco.
Alterações: Podemos atualizar este Aviso de tempos em tempos. Verifique periodicamente se há atualizações. Se você não concordar com quaisquer alterações que fizermos, você deve parar de interagir conosco. Quando exigido pela legislação aplicável, iremos notificá-lo sobre quaisquer alterações a este Aviso, publicando uma atualização em nosso Site ou de outra maneira adequada.
</p>

<p>
<b>2. Dados pessoais que coletamos e divulgamos</b><br/>
Categorias de dados pessoais coletados Divulgações de Dados Pessoais
Identificadores, como seu nome, endereço de e-mail, endereço postal, número de telefone e identificadores de dispositivos (por exemplo, identificadores de publicidade e endereço IP).
</p>

<p>
<b>3. Como processamos dados pessoais</b><br/>
Podemos processar seus dados pessoais para os objetivo abaixo:
</p><p>
Objetivo do Processamento Base legítima
Para fornecer nossos produtos, serviços e propriedades digitais a você,inclusive processamento e cumprimento de transações; permitindo que você acesse as Propriedades Digitais e nossos Serviços; operar, manter e melhorar nossas propriedades e serviços digitais; comunicar-se com você, por exemplo, completando suas solicitações de suporte ou fornecendo atualizações de segurança; e diagnosticar, reparar e rastrear problemas de serviço e qualidade.
</p><p>
Interesses legítimos; Contrato; Obrigações legais
</p><p>
Para nossos próprios objetivos de negócios, inclusive a manutenção de registros comerciais internos e a realização de relatórios internos; coleta de pagamentos e execução de contabilidade e funções comerciais similares; auditar e gerenciar projetos relacionados aos nossos Serviços; realizar gerenciamento de segurança de TI e tarefas relacionadas a TI, como administração de nossas tecnologias e rede; avaliar e melhorar nossos negócios, serviços e propriedades digitais; e realizar pesquisa e desenvolvimento de novos produtos e serviços; e processar suas respostas de pesquisa e questionário.
</p><p>
Interesses legítimos; Obrigações legais
</p><p>
Por motivos legais, de segurança ou de proteção, inclusive para cumprir requisitos legais; estabelecer, exercer ou defender-se contra reivindicações legais; proteger a segurança, proteção e integridade de nossa propriedade e os direitos daqueles que interagem conosco ou com outros; investigar qualquer conteúdo ou conduzir violações de políticas; e detectar, prevenir e responder a incidentes de segurança ou outras atividades maliciosas, enganosas, fraudulentas ou ilegais.
</p><p>
Esses objetivos de segurança também podem envolver a coleta e o processamento de categorias especiais de dados pessoais (ou seja, dados de saúde), para visitas ao escritório e eventos quando necessário por motivos de interesse de saúde pública ou exigido pela legislação aplicável.
</p><p>
Interesses legítimos; Obrigações legais; Interesse público
</p><p>
Para comercializar nossos produtos e Serviços ou de terceiros, como nossos parceiros de negócios, inclusive para solicitar ou publicar depoimentos ou comentários sobre nossos produtos e serviços; enviar a você comunicações promocionais e de marketing ou recomendações de produtos (via e-mail, telefone ou outros canais on-line e off-line) sobre nossos Serviços ou de terceiros; facilitar sua participação em um concurso ou evento; avaliar impressões de anúncios ou envolver-se na personalização de anúncios contextuais.
</p><p>
Você pode optar por não receber comunicações de marketing aqui ou clicando no link "cancelar inscrição" na parte inferior de nossas comunicações de marketing. Você também pode gerenciar suas preferências de e-mail aqui. Observe que alguns de nossos materiais e informações de marketing podem usar tecnologias de rastreamento e ferramentas analíticas para nos ajudar a entender suas preferências. Observe que alguns de nossos materiais e informações de marketing podem usar tecnologias de rastreamento e ferramentas analíticas para nos ajudar a entender suas preferências.
</p><p>
Consentimento (quando exigido pela legislação); Interesses legítimos
</p><p>
Para atender a uma solicitação de indicação quando você usa nosso serviço de indicação para contar a um amigo sobre nossos Serviços, inclusive usando o nome, endereço de e-mail, título e nome da empresa que você nos fornece para entrar em contato com a pessoa que está indicando.
</p><p>
Você só pode fornecer dados pessoais de outras pessoas se tiver o consentimento delas para fazê-lo.
</p><p>
Consentimento (quando exigido pela legislação); Interesses legítimos
</p><p>
Diversidade, equidade e inclusão, como a promoção de iniciativas de diversidade, equidade e inclusão e representação em nossos negócios (quando autorizado pela legislação aplicável).
</p><p>
Consentimento (quando exigido pela legislação); Interesses legítimos
</p><p>
Transações corporativas, como vendas, fusões, aquisições, reorganizações, falências e outros eventos corporativos.
</p><p>
Interesses legítimos; Obrigações legais
</p><p>
Quando você voluntariamente concorda em ter seus dados pessoais processados.
</p><p>
Consentimento
</p><p>
A Plataforma Ordine honrará os direitos dos titulares de dados na medida exigida pela legislação. Você pode ter o direito de acessar, corrigir, atualizar e, em alguns casos, solicitar a exclusão de seus dados pessoais (sujeito a exceções). Você pode enviar uma solicitação aqui.
</p>

<p>
<b>4. Fontes de dados pessoais</b><br />
Informações que você nos fornece diretamente, inclusive quando você se registra e se comunica conosco diretamente por meio de nossas Propriedades Digitais, visita nossos escritórios ou participa de nossos eventos, marketing e atividades de divulgação.
Informações coletadas de seu empregador, colegas de trabalho ou amigos, inclusive informações sobre representantes ou outros funcionários de nossos clientes, fornecedores, investidores e parceiros de negócios atuais, anteriores e potenciais. Também podemos receber suas informações de um amigo como parte de uma indicação de nossos Serviços.
Informações coletadas automaticamente, inclusive informações técnicas sobre suas interações com nossas Propriedades Digitais (como endereço IP, preferências de navegação e histórico de compras). Mais informações estão disponíveis na Seção 5abaixo e em nosso Aviso de Cookie.
Informações de fontes públicas, inclusive informações de registros públicos e informações que você compartilha em fóruns públicos, como mídias sociais.
Informações de terceiros, inclusive informações de provedores de conteúdo e serviços terceiros, entidades com as quais temos parceria para vender ou promover produtos e serviços e redes de mídia social (inclusive widgets relacionados a essas redes, como o botão “Curtir do Facebook” ).
Podemos combinar informações que recebemos de diversas fontes descritas neste Aviso, inclusive fontes de terceiros e fontes públicas, e usá-las ou divulgá-las para os objetivos identificados abaixo.
</p>

<p>
<b>5. Cookies e Tecnologias de Rastreamento</b><br />
Usamos cookies e outras tecnologias de rastreamento e oferecemos a você a opção de gerenciar essas configurações conforme descrito em nosso Aviso de Cookies. Algumas tecnologias de rastreamento nos permitem rastrear a atividade do seu dispositivo ao longo do tempo e entre dispositivos e sites. Embora alguns navegadores tenham incorporado as preferências Do Not Track ou DNT, não respeitamos esses sinais de navegadores da Web no momento.
</p>

<p>
<b>6. Segurança e retenção</b><br />
Mantemos procedimentos de segurança razoáveis e medidas técnicas e organizacionais para proteger seus dados pessoais contra destruição, perda, divulgação, alteração ou uso acidental ou ilícito.
</p><p>
Seus dados pessoais serão geralmente retidos pelo tempo necessário para cumprir os objetivo para os quais coletamos os dados pessoais. Uma vez que você/sua empresa tenha encerrado o relacionamento contratual conosco ou encerrado seu relacionamento conosco, podemos reter seus dados pessoais em nossos sistemas e registros para: assegurar o cumprimento adequado das cláusulas que continuam em vigor em contratos rescindidos ou para outros propósitos legítimos de negócios, como para evidenciar nossas práticas comerciais e obrigações contratuais, para fornecer a você informações sobre nossos produtos e serviços ou para estar em conformidade com os requisitos jurídicos, fiscais ou contábeis aplicáveis. Quando não tivermos uma necessidade de negócios legítima em andamento nem base legal para processar os seus dados pessoais, estes serão excluídos, anonimizados ou agregados ou, se isso não for possível (p. ex., porque seus dados pessoais foram armazenados em arquivos de backup), armazenaremos com segurança seus dados pessoais e os manteremos isolados de qualquer processamento adicional até que a exclusão seja possível. 
</p>

<p>
<b>7. Privacidade de menores</b><br />
Nossos Sites e Serviços não são direcionados a menores de 16 anos de idade e não coletamos intencionalmente dados pessoais on-line diretamente de menores. Se você é um dos pais ou tutor de uma criança menor de idade e acredita que a criança nos divulgou dados pessoais on-line, entre em contato conosco usando os detalhes fornecidos na Seção 9 abaixo.
</p>

<p>
8. Links externos<br />
Ao interagir conosco, você pode encontrar links para sites externos ou outros serviços on-line, inclusive aqueles incorporados em anúncios de terceiros. Não controlamos e não somos responsáveis pelas políticas de privacidade e coleta de dados desses sites e serviços de terceiros. Você deve consultar esses terceiros e seus respectivos avisos de privacidade para obter mais informações ou se tiver alguma dúvida sobre suas práticas.
</p>

<p>
<b>9. Informações de contato</b> <br />
Em caso de dúvidas ou reclamações com relação a esta Política ou sobre as práticas de privacidade do Grupo Plataforma Ordine, entre em contato conosco através do e-mail proenca@proencait.com
</p><p>
Se você tiver uma preocupação não resolvida sobre privacidade ou uso de dados sujeita à nossa certificação de Escudo de privacidade que não abordamos de maneira satisfatória, entre em contato com nosso provedor terceiro de resolução de disputas com sede nos EUA (gratuitamente) aqui.
</p>
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { PrivacidadeComponent } from "./privacidade/privacidade.component";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      {
        path: "cadastro-basico",
        loadChildren: () =>
          import("./cadastro-basico/cadastro-basico.module").then((m) => m.CadastroBasicoModule),
      },
      {
        path: "financeiro",
        loadChildren: () =>
          import("./financeiro/financeiro.module").then((m) => m.FinanceiroModule),
      },
      {
        path: "consultas",
        loadChildren: () =>
          import("./consultas/consultas.module").then((m) => m.ConsultasModule),
      },
      {
        path: "os",
        loadChildren: () =>
          import("./os/os.module").then((m) => m.OsModule),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "automoveis",
        loadChildren: () =>
          import("./automoveis/automoveis.module").then((m) => m.AutomoveisModule),
      },
      {
        path: "task",
        loadChildren: () =>
          import("./task/task.module").then((m) => m.TaskModule),
      },
      {
        path: "tables",
        loadChildren: () =>
          import("./tables/tables.module").then((m) => m.TablesModule),
      },
      {
        path: "multilevel",
        loadChildren: () =>
          import("./multilevel/multilevel.module").then(
            (m) => m.MultilevelModule
          ),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "politica-de-privacidade",
    component: PrivacidadeComponent,
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { AuthUser } from "../models/auth-user";
import { Resp } from "../models/resp";
import { RefreshToken } from "../models/refresh-token";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private currentAuthUserSubject: BehaviorSubject<AuthUser>;
  public currentAuthUser: Observable<AuthUser>;
  public produtosNaTransferenciaValue = new BehaviorSubject(this.produtosNaTransferencia);
  public ferramentasNaTransferenciaValue = new BehaviorSubject(this.ferramentasNaTransferencia);
  public veiculoNaTransferenciaValue = new BehaviorSubject(this.veiculoNaTransferencia);


  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );

    this.currentUser = this.currentUserSubject.asObservable();

    this.currentAuthUserSubject = new BehaviorSubject<AuthUser>(
      JSON.parse(localStorage.getItem("currentAuthUser"))
    );

    this.currentAuthUser = this.currentAuthUserSubject.asObservable();
  }

  public set veiculoNaTransferencia(value) {
    this.veiculoNaTransferenciaValue.next(value); 
    localStorage.setItem('veiculoNaTransferencia', value);
  }
 
  public get veiculoNaTransferencia() {
    return localStorage.getItem('veiculoNaTransferencia');
  }

  public set produtosNaTransferencia(value) {
    this.produtosNaTransferenciaValue.next(value); 
    localStorage.setItem('produtosNaTransferencia', value);
  }
 
  public get produtosNaTransferencia() {
    return localStorage.getItem('produtosNaTransferencia');
  }

  public set ferramentasNaTransferencia(value) {
    this.ferramentasNaTransferenciaValue.next(value); 
    localStorage.setItem('ferramentasNaTransferencia', value);
  }
 
  public get ferramentasNaTransferencia() {
    return localStorage.getItem('ferramentasNaTransferencia');
  }

  


  public refreshToken(refreshToken: RefreshToken) {
     return this.http
        .post(`${environment.apiUrl}refresh-token`, refreshToken );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentAuthUserValue(): AuthUser {
    return this.currentAuthUserSubject.value;
  }

  getByCredentials(email: string, senha: string) {
    return this.http
     .post(`${environment.apiUrl}credentials`, {
       'email': email,
       'senha': senha
     }).pipe(
      map((user: Resp<User>) => {
        localStorage.setItem("currentUser", JSON.stringify(user.data));
        this.currentUserSubject.next(user.data);
        return user.data;
      })
    );
  }
  
  changePassword(usuarioId: string, newPassword: string) {
    return this.http.patch(`${environment.apiUrl}usuario/password/${usuarioId}`, {id: usuarioId, newPassword: newPassword});
  }

  resetPassword(email: string) {
    return this.http.post(`${environment.apiUrl}reset-password`, {email: email});
  }

  resetClientPassword(email: string) {
    return this.http.post(`${environment.apiUrl}reset-client-password`, {email: email});
  }


  login(email: string, senha: string, empresaId: string, perfilId: string) {
    return this.http
      .post<any>(`${environment.apiUrl}login`, {
        email: email,
        senha: senha,
        empresaId: empresaId,
        perfilId: perfilId
      })
      .pipe(
        map((authUser: Resp<AuthUser>) => {
          // Grava detalhes do usuário e do token jwt no localstorage para manter o usuário logado por todo o sistema
          localStorage.setItem("currentAuthUser", JSON.stringify(authUser.data));
          this.currentAuthUserSubject.next(authUser.data);
          return authUser;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("stateurl");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}

import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
  
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private config: Partial<IndividualConfig>
  
  constructor(private toastr: ToastrService) { 
     this.config = {
        disableTimeOut: false,
        progressBar: true,
        closeButton: true,
        messageClass: 'break-line'
     }; 

  }
  
  showSuccess(message, title, disableTimeOut: boolean = false){
     this.config.disableTimeOut = disableTimeOut;
     return this.toastr.success(message, title, this.config)
  }
  
  showError(message, title, disableTimeOut: boolean = false){
      this.config.disableTimeOut = disableTimeOut;
      this.toastr.error(message, title, this.config)
  }
  
  showInfo(message, title, disableTimeOut: boolean = false){
      this.config.disableTimeOut = disableTimeOut;
      this.toastr.info(message, title, this.config)
  }
  
  showWarning(message, title, disableTimeOut: boolean = false){
      this.config.disableTimeOut = disableTimeOut;
      this.toastr.warning(message, title, this.config )
  }
  
}
import { AuthService } from "../service/auth.service";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { NotificationService } from "../service/notification.service";
import { RefreshToken } from "../models/refresh-token";
import { LoggingService } from "../service/logging.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshToken: RefreshToken = {refreshToken: ""};
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authenticationService: AuthService,
    private notificationService: NotificationService,
    private loggingService: LoggingService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error) => {
        this.loggingService.logException(error);

        if (error?.status === 401) {
          return this.handle401Error(req, next);          
        }else 
        if (error.status === 0 || error.status === 500) {
            this.notificationService.showError("Houve um erro de comunicação com o servidor","");
            console.log(error);
        } else if (error?.error?.errors) {
            this.notificationService.showError(error?.error?.errors,"");
        } else if (error?.errors) {
            this.notificationService.showError(error?.errors,"");
        } else {
            this.notificationService.showError(error.statusText,"");
        }
        
        const erro = error?.error?.errors || error.statusText;
        return throwError(() => new Error(erro));
      }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
       this.isRefreshing = true;
       this.refreshTokenSubject.next(null);
       
       if (this.refreshToken)
          this.refreshToken.refreshToken = JSON.parse(localStorage.getItem("currentAuthUser")).refreshToken;
          return this.authenticationService.refreshToken( this.refreshToken ).pipe(
             switchMap((authUser: any) => {
                this.isRefreshing = false;

                localStorage.setItem("currentAuthUser", JSON.stringify(authUser.data));
                this.refreshTokenSubject.next(authUser.data.accessToken);
                   
                return next.handle(this.addTokenHeader(request, authUser.data.accessToken));
             }),
              catchError((err) => {
                 this.isRefreshing = false;
                 //Sessão expirou
                 this.authenticationService.logout();
                 //this.notificationService.showError("A sua sessão expirou",""); Esta msg já vem da API
                 location.reload();
                 return throwError(() => new Error(err));
             })
          );
     }
       
     return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token)))
     );
  }


  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
       headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }  
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Estoque } from "src/app/core/models/estoque";
import { RespPaged } from "src/app/core/models/resp-paged";
import { environment } from "src/environments/environment";

@Injectable()
export class EstoqueService{
    constructor(private httpClient: HttpClient) {}

    select(grupoId: string = "", ativo = true) {
      return this.httpClient.get<RespPaged<Estoque>>(`${environment.apiUrl}estoque/select?grupoId=${grupoId}&ativo=${ativo}`);
    }    

}
import { RouteInfo } from "./sidebar.metadata";

export class SidebarItems {

  public static get(): RouteInfo[] {

    const ROUTES: RouteInfo[] = [
      {
        path: "/cadastro-basico/empresa/",
        title: "EMP",
        description: "Empresa",
        iconType: "feather",
        icon: "trello",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },

      {
        path: "",
        title: "",
        description: "Cadastro Básico",
        iconType: "feather",
        icon: "copy",
        class: "menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/cadastro-basico/configuracoes-gerais/",
            title: "PAR",
            description: "Configurações Gerais",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          }, 
          {
            path: "/cadastro-basico/fornecedor/",
            title: "FOR",
            description: "Fornecedor",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          }, 
          {
            path: "/cadastro-basico/categoria/",
            title: "CAT",
            description: "Categoria",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          }, 
          {
            path: "/cadastro-basico/marca/",
            title: "MAR",
            description: "Marca",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          }, 
          {
            path: "/cadastro-basico/grupo/",
            title: "GRP",
            description: "Grupo de Clientes",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          }, 
          {
            path: "/cadastro-basico/cabecalho-proposta/",
            title: "CBP",
            description: "Cabeçalho Proposta",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          },
          {
            path: "/cadastro-basico/condicoes-comerciais/",
            title: "CCP",
            description: "Condições Comerciais",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          }, 
          {
            path: "/cadastro-basico/notificacoes/",
            title: "NTF",
            description: "Notificações",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          }, 
          {
            path: "/cadastro-basico/tipo-servico-os/",
            title: "TSOS",
            description: "Tipo de Serviço OS",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          },           
        ]
      },  
      {
        path: "",
        title: "",
        description: "Cadastro Financeiro",
        iconType: "feather",
        icon: "dollar-sign",
        class: "menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/financeiro/tabela-preco-produto/",
            title: "TPP",
            description: "Preço Produto",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
          },
          {
            path: "/financeiro/tabela-preco-servico/",
            title: "TPS",
            description: "Preço Serviço",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
          }
        ]
      },    
      {
        path: "/cadastro-basico/usuario/",
        title: "USU",
        description: "Usuário",
        iconType: "feather",
        icon: "user-plus",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "/cadastro-basico/cliente/",
        title: "CLI",
        description: "Cliente",
        iconType: "feather",
        icon: "users",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "/cadastro-basico/produto/",
        title: "PRO",
        description: "Produto",
        iconType: "feather",
        icon: "grid",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "/cadastro-basico/ferramenta/",
        title: "FER",
        description: "Ferramenta",
        iconType: "feather",
        icon: "tool",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "/cadastro-basico/servico/",
        title: "SER",
        description: "Servico",
        iconType: "feather",
        icon: "trending-up",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "",
        title: "",
        description: "Estoque",
        iconType: "feather",
        icon: "layers",
        class: "menu-toggle",
        groupTitle: false,
        badge: '',
        badgeClass: 'badge bg-green sidebar-badge float-end',
        submenu: [
          {
            path: "/cadastro-basico/produto-estoque/",
            title: "EST",
            description: "Produto",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: '',
            badgeClass: "",
            submenu: [],
          },
          {
            path: "/cadastro-basico/ferramenta-estoque/",
            title: "EST",
            description: "Ferramenta",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: '',
            badgeClass: 'badge bg-green sidebar-badge float-end',
            submenu: [],
          }
        ],
      },
      {
        path: "/os/os/",
        title: "OS",
        description: 'Ordem de Serviço',
        iconType: "feather",
        icon: "trello",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
      },
      {
        path: "",
        title: "",
        description: "Veículos",
        iconType: "feather",
        icon: "truck",
        class: "menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: 'badge bg-green sidebar-badge float-end',
        submenu: [
          {
            path: "/automoveis/fabricante/",
            title: "FAB",
            description: "Fabricante",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
          },
          {
            path: "/automoveis/veiculo/",
            title: "VEC",
            description: "Veículo",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
          },

        ]  
      },  
      {
        path: "",
        title: "",
        description: "Consultas",
        iconType: "feather",
        icon: "search",
        class: "menu-toggle",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [
          {
            path: "/consultas/pecas-utilizadas/",
            title: "CPU",
            description: "Peças utilizadas",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          },
          {
            path: "/consultas/historico-peca/",
            title: "CPU",
            description: "Histórico de Peça",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          },
          {
            path: "/consultas/estoques-profissionais/",
            title: "CEP",
            description: "Estoques de profissionais",
            iconType: "feather",
            icon: "",
            class: "",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            submenu: [],
          }

        ]   
      }      
    ]
    return ROUTES;
  }  
}